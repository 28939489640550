(function() {
	var isRegionsMerged = true;
	var m_currentLoaded = {
		js : [],
		pages : []
	};

	et.effect = {
		pgout : "fadeOut",
		pgin : "fadeIn",
		speed : "quick"
	};
	
	var C = et.constants;
	var curPage;

	/** used by presenter to specify the dependencies */
	et.presenter.prototype.loadRegions = function(p_regions, p_handler, p_controller, p_pageDef, p_merged) {
		if (p_regions) {
			var self = this;
			this.regions = {};
			if(!p_merged && isRegionsMerged){
				var merged = "merged." + p_pageDef.name.replace(/\./g, '_') + "_regions";
				loadMergedRegion(merged, p_regions, p_handler, p_controller, this).done(function(){
					loadRegion(p_regions, 0, p_handler, p_controller, self, true);
				});
			}else {
				loadRegion(p_regions, 0, p_handler, p_controller, this, p_merged);
			}
			//p_handler.call();
		} else {
			if (p_handler)
				p_handler.call(this);
		}
	};

	/** load the dependent files*/
	var loadRegion = function(p_regions, p_index, p_handler, p_controller, p_presenter, p_merged) {
		var rgDef = p_controller.regions[p_regions[p_index]];
		loadPage(rgDef, false, p_controller, (function(pp_regions, pp_index, pp_regionId, pp_rgDef, pp_controller, pp_presenter, pp_handler, pp_merged) {
			return function() {
				pp_presenter.regions[pp_regionId] = et.provide(pp_rgDef.name);
				if (pp_index == (pp_regions.length - 1)) {
					pp_handler.call();
				} else {
					loadRegion(pp_regions, pp_index + 1, pp_handler, pp_controller, pp_presenter, pp_merged);
				}
			};
		})(p_regions, p_index, p_regions[p_index], rgDef, p_controller, p_presenter, p_handler, p_merged), p_merged);

	};
	
	/** load the dependent files*/
	var loadMergedRegion = function(merged, p_regions, p_handler, p_controller, p_presenter) {
		var defer = $.Deferred();
		var mgDef = {name: merged};
		loadPage(mgDef, false, p_controller, function() {
			for(var i = 0; i < p_regions.length; i ++){
				var regId = p_regions[i];
				var regDef = p_controller.regions[regId];
				//p_presenter.regions[regId] = et.provide(regDef.name);
			}
			defer.resolve();
			//p_handler.call();
			
		});
		return defer;

	};

	/** initialize and bind the controller, usually called by module. */
	et.controller.prototype.bind = function(p_container) {
		var controller = this;
		controller.container = p_container;

		$(document).on('pushstate:done', function(event, data) {
			if (data && data.action) {
				console.log('state changed to ' + data.action);
				pageChanged(data.action, controller);
			} else {
				console.log('missing target action url, reloading page.');
				window.location.reload();
			}
		});
		
		$(document).on('pushstate:disable', function(event, data) {
			if (data && data.url) {
				console.log('loading full page at ' + data.url);
				window.location.href = data.url;
			} else {
				window.location.reload();
			}
		}); 
		
		if ("onpopstate" in window) {
			window.onpopstate = (function(p_controller) {
				return function(event) {
					var path = window.location.href;
					var d = path.split('/');
					var action = d[d.length-1];
					pageChanged(action, p_controller);
				};
			})(controller);
		}

		if (window.location.href) {
			var dirs = window.location.href.split("/");
			var landing = dirs[dirs.length-1];
			pageChanged(landing, controller);
		} else {
			//landing page
			pageChanged(controller.landing, controller);
		}
		
		$('body').on('click', 'a', function(e) {
			var link = $(this).attr('href');

			// rewrite link if under same workspace
			if (link && link.indexOf('/') != -1) {
				try {
					var sameWorkspace = true;

					var currentPath = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
					var targetPath = link.substring(0, link.lastIndexOf('/'));

					console.log("currentPath = " + currentPath);
					console.log("targetPath = " + targetPath);

					var currentPaths = currentPath.split('/');
					var targetPaths = targetPath.split('/');

					var targetPathCompareStartIndex = 0;
					for (var i = 0; i < targetPaths.length - 1; i++) {
						if (targetPaths[i] == "..") {
							targetPathCompareStartIndex = i + 1;
						}
						else {
							break;
						}
					}

					console.log("targetPathCompareStartIndex = " + targetPathCompareStartIndex);

					for (var i = 0; i < targetPaths.length - targetPathCompareStartIndex; i++) {
						console.log("i = " + i + "; " + currentPaths[currentPaths.length - 1 - i] + "; " + targetPaths[targetPaths.length - 1 - i]);
						if (currentPaths[currentPaths.length - 1 - i] !== targetPaths[targetPaths.length - 1 - i]) {
							sameWorkspace = false;
							break;
						}
					}

					if (sameWorkspace) {
						link = link.substring(link.lastIndexOf('/') + 1, link.length);
						console.log("link has been rewritten to: " + link);
					}
				}
				catch (e) {
					console.log(e);
				}
			}

			//do nothing if it's bookmark or javascript
			if(/^#.*$/i.test(link) || /^javascript.*$/i.test(link)){
				return;
			}
			
			var regex = /^[^\/#]+$/i;
			console.log('action url' + link);
			
			if (link && regex.test(link)) {
				link = et.createHash(link, et.DefaultPageParams);
				$(this).attr('href', link)
				e.preventDefault();  // mute the native anchor event
				var path = window.location.href;
				var d = path.split('/');
				var action = d[d.length-1];
				if (action && action !== "") {
					path = path.replace(action, link);
				} else {
					path = path + link;
				}
				pjax(link, '', path);
			}

		});
		
	};

	et.controller.prototype.unbind = function() {

	};

	/** programmatically navigate to a page*/
	   et.controller.prototype.navigate = function(p_page, p_params, p_bookmark) {
        if (p_bookmark) {
            et.DefaultPageParams[C.BOOKMARK] = p_bookmark;
        }

		var isExternal = p_page.match(/^(http|https)/) || p_page.match(/.*\/.*/);
		//load default params only if it's internal page navigation
		p_params = isExternal ? p_params : et.loadDefaultPageParams(p_params);

		if (p_page) {
			if (isExternal)//external navigation
			{
				window.location = et.createExternalUrl(p_page, p_params);
			} else//internal navigation
			{
				var currentHash = window.location.hash.substring(1);
				var inx = currentHash ? currentHash.indexOf("?") : -1;
				if (inx > -1) {
					currentHash = currentHash.substr(0, inx);
				}
				
				var pageHash = et.createHash("" + p_page, p_params);
				
				if (this.pages[currentHash] && this.pages[currentHash].skipHistory) {
					window.location.replace(pageHash);
				} else {
					// window.location.hash = pageHash;
					var path = window.location.href;
					var d = path.split('/');
					var action = d[d.length-1];
					if (action) {
						path = path.replace(action, pageHash);
					} else {
						path = path + pageHash;
					}
					pjax(pageHash, '', path);
				}
			}

		}
	};
	
	/**
	 *clear the bookmark
	 */
	et.controller.prototype.clearBookmark = function() {
        delete et.DefaultPageParams[C.BOOKMARK];
    };
    
    et.presenter.prototype.clearBookmark = function() {
        et.controller.prototype.clearBookmark();
    };


	/** 
	 *
	 * Method to call when the presenter is done loading the page
	 * 
	 * params:
	 *   forward - next page to go to
	 *   params - page parameters
	 *   useBookmark - if the bookmark will be used
	 *   clearBookmark - if the bookmark needs to be cleared before navigation
	 */
	et.presenter.prototype.pageDone = function(p_param) {
		var bookmark = et.DefaultPageParams[C.BOOKMARK];
		if (!p_param)
			return;
		if (p_param.forward) {
			p_param.forward = p_param.forward.replace('#', '');
		}
		if (p_param.clearBookmark)
			this.clearBookmark();
		if (!p_param.forward && p_param.useBookmark && !bookmark)
			return;

		setTimeout((function(pp_page, pp_param, pp_bookmark) {
			return function() {
				if (pp_param.useBookmark) {
					if (!pp_bookmark) {
						pp_page.controller.navigate(pp_page.controller.pages[pp_page.pageId].forward[pp_param.forward], pp_param.params);
					} else {
						pp_page.controller.clearBookmark();
						pp_page.controller.navigate(bookmark);
					}
				} else if (p_param.forward) {
					var forward = pp_page.controller.pages[pp_page.pageId].forward[pp_param.forward];
					if (forward) {
						pp_page.controller.navigate(forward, pp_param.params);
					} else {
						pp_page.controller.navigate(pp_param.forward, pp_param.params);
					}
				} else {
					console.log("WARN: forward not specified.");
				}
			};
		})(this, p_param, bookmark), 0);
		return true;
	};

	/** 
	 *
	 * Method to call to forward to error page
	 * 
	 * params:
	 *   errorMessage
	 *   showDetails - by default true
	 */
	et.presenter.prototype.forwardToError = function(errorMessage, showDetails) {
		var parentPage = this.name;
		this.pageDone({
            forward: "Error",
            params: {
                "details": ((showDetails) ? showDetails : true).toString(),
                "errMsg": errorMessage,
                "parent": parentPage
            }
        });
	};
	
	/**
	 * This method will reload the current presenter & view. Browser history/bookmark won't
	 * be affected. Can be used to reset all inputs on the page.
	 */
	et.presenter.prototype.reload = function() {
		pageChanged(this.pageId, this.controller, true);
	};

	/** handle the bookmark change*/
	var pageChanged = function(p_pageId, p_controller, p_forceLoad) {
		if(!p_forceLoad && curPage){
			// if forceLoad!=true and only hash changed, do nothing
			var curPgNoHash, newPgNoHash;
			var inxHash = curPage.indexOf("#");
			if(inxHash > -1){
				curPgNoHash = curPage.substr(0, inxHash);
			}else {
				curPgNoHash = curPage;
			}
			inxHash = p_pageId.indexOf("#");
			if(inxHash > -1){
				newPgNoHash = p_pageId.substr(0, inxHash);
			}else {
				newPgNoHash = p_pageId;
			}
			if(newPgNoHash == curPgNoHash){
				return;
			}
		}
		// set the current page
		curPage = p_pageId;
		
		//process page change
		var inx = p_pageId ? p_pageId.indexOf("#") : -1;
		if (inx > -1) {
			p_pageId = p_pageId.substr(0, inx);
		}
		
		inx = p_pageId ? p_pageId.indexOf("?") : -1;
		var pageParamString;
		if (inx > -1) {
			pageParamString = p_pageId.substr(inx + 1);
			p_pageId = p_pageId.substr(0, inx);
		}
		console.log("currentpage id = " + curPage + ", page id = " + p_pageId);

		if (!p_pageId)
			p_pageId = p_controller.landing;
		
        //use mobile version if it's on mobile device
        var pageDef = p_controller.pages[p_pageId];
        if (et.isMobile()) {
            pageDef = p_controller.pages[p_pageId + "_m"] || pageDef;
        }
            
		var defer = p_controller.pageChanged(p_pageId, pageDef, p_controller);
		defer.done(function(p_forwardTo, p_pageParam) {
		    // forward to different page
		    if(p_forwardTo){
		        p_controller.navigate(p_forwardTo, p_pageParam);
		        return;
		    }
			

			//var page = pageObj.name;
			//var path = pageObj.path || "";
			//var regions = pageObj.regions;
			console.log("page name = " + pageDef.name + ", prams = " + pageParamString);

			//check if the page requires login, if so then go to login page and save this page into bookmark.
			var loginDeferred = $.Deferred();
			if (pageDef.loginRequired) {
				var loginRequireDeferred = p_controller.requiresLogin(p_pageId, et.getPageParameters());
				loginRequireDeferred.done(function(data) {
					loginDeferred.resolve(data);
				}).fail(function(){
				    loginDeferred.reject();
				});
			} else {
				loginDeferred.resolve();
			}

			loginDeferred.done(function(data) {
				if (pageDef) {
					loadPage(pageDef, true, p_controller, (function(pp_pageId, pp_pageDef, pp_controller) {
						return function() {
							var presenter = et.provide(pp_pageDef.name);
							presenter.controller = pp_controller;
							presenter.pageId = pp_pageId;
							et.onPresenterCreated.fire(presenter);
							presenter.go(pp_controller.container);
							et.onPageChanged.fire(presenter);
						};
					})(p_pageId, pageDef, p_controller));
				}
			}).fail(function(){
				et.goToLoginPage();
			});
		});
		defer.fail(function(err) {



			//TODO show a error page
			if(err.status.mgs) {
				p_controller.navigate("Error", {errMsg:err.status.mgs});
				//p_controller.container.text(err.status.mgs);
			}else{
				p_controller.navigate("Error", {errMsg:"Error verifying access to page",nextPage:err.homePage});
				//p_controller.container.text("Error verifying access to page");
			}
			console.log(err);
		});

	};

	var loadPage = function(p_pageDef, p_unload, p_controller, p_handler, p_merged) {
		if (p_unload && m_currentLoaded.pages && m_currentLoaded.pages.length > 0) {
			//p_controller.container[et.effect.pgout](et.effect.speed, (function(pp_pageId, pp_unload, pp_controller, pp_handler)
			//{
			//	return function()
			//	{
			if(p_merged)
				_loadPageMerged(p_pageDef, p_unload, p_controller, p_handler);
			else
				_loadPage(p_pageDef, p_unload, p_controller, p_handler);
			//pp_controller.container[et.effect.pgin](et.effect.speed);
			//	}
			//})(p_pageId, p_unload, p_controller, p_handler));
		} else {
			if(p_merged)
				_loadPageMerged(p_pageDef, p_unload, p_controller, p_handler);
			else
				_loadPage(p_pageDef, p_unload, p_controller, p_handler);
		}
	};
	
	var pjax = function(action, name, path) {
		if (et.pushStateSupport) {
	        history.pushState({'action': action}, '', path); // push current state to history stack
	        $(document).trigger('pushstate:done', {'action': action, 'url': path});  // notify listeners
		} else {
		    $(document).trigger('pushstate:disable', {'action': action, 'url': path});
		}
	};

	var _loadPage = function(p_pageDef, p_unload, p_controller, p_handler) {
		if (p_unload) {
			//unload the js first
			for (var i = 0; i < m_currentLoaded.js.length; i++) {
				document.getElementsByTagName("head")[0].removeChild(m_currentLoaded.js[i]);
				console.log(m_currentLoaded.js[i].src + " unloaded.");
			}
			m_currentLoaded.js = [];
			for (var i = 0; i < m_currentLoaded.pages.length; i++) {

				var pageId = m_currentLoaded.pages[i];
				var idx = pageId.lastIndexOf(".");
				var pkg = et.provide(pageId.slice(0, idx));
				pkg[pageId.slice(idx + 1)] = undefined;
				delete m_currentLoaded.pageId;
				console.log(pageId + " unloaded.");
			}
			m_currentLoaded.pages = [];

		}

		et.debug = et.DefaultPageParams[C.DEBUG] = et.getDebug();
		var tmp = p_pageDef.name.split(".").join("/") + (et.debug ? ".js" : ".js");
		console.log("loading page: " + tmp);
		var js = document.createElement('script');
		js.type = "text/javascript";
		js.src = (p_pageDef.path || "") + tmp + "?v=" + et.version;
		js.pkg = p_pageDef.name;
		if (js.readyState) {
			// IE
			js.onreadystatechange = (function(pp_pageDef, pp_handler, pp_controller) {
				return function() {
					if (this.readyState == "loaded" || this.readyState == "complete") {
						this.onreadystatechange = null;
						console.log(this.src + " loaded.");
						if (pp_pageDef.requires) {
							et.loadScript(pp_pageDef.requires, (function(ppp_pageDef, ppp_handler, ppp_controller) {
								return function() {
									var presenter = et.provide(ppp_pageDef.name);
									presenter._viewPath = ppp_pageDef.path;
									presenter.loadRegions(ppp_pageDef.regions, ppp_handler, ppp_controller, ppp_pageDef);
								};
							})(pp_pageDef, pp_handler, pp_controller));
						} else {
							var presenter = et.provide(pp_pageDef.name);
							presenter._viewPath = pp_pageDef.path;
							if(!pp_pageDef.name.startsWith('merged')) presenter.loadRegions(pp_pageDef.regions, pp_handler, pp_controller, pp_pageDef);
							else pp_handler.call();
						}
					}
				};
			})(p_pageDef, p_handler, p_controller);
		} else {
			// Others
			js.onload = (function(pp_pageDef, pp_handler, pp_controller) {
				return function() {
					console.log(this.src + " loaded.");
					if (pp_pageDef.requires) {
						et.loadScript(pp_pageDef.requires, (function(ppp_pageDef, ppp_handler, ppp_controller) {
							return function() {
								var presenter = et.provide(ppp_pageDef.name);
								presenter._viewPath = ppp_pageDef.path;
								presenter.loadRegions(ppp_pageDef.regions, ppp_handler, ppp_controller, ppp_pageDef);
							};
						})(pp_pageDef, pp_handler, pp_controller));
					} else {
						var presenter = et.provide(pp_pageDef.name);
						presenter._viewPath = pp_pageDef.path;
                        if(!pp_pageDef.name.startsWith('merged')) presenter.loadRegions(pp_pageDef.regions, pp_handler, pp_controller, pp_pageDef);
						else pp_handler.call();
					}

				};
			})(p_pageDef, p_handler, p_controller);
		}
		document.getElementsByTagName("head")[0].appendChild(js);

		m_currentLoaded.pages.push(p_pageDef.name);
		m_currentLoaded.js.push(js);
	};
	
	var _loadPageMerged = function(p_pageDef, p_unload, p_controller, p_handler) {
		if (p_unload) {
			//unload the js first
			for (var i = 0; i < m_currentLoaded.js.length; i++) {
				document.getElementsByTagName("head")[0].removeChild(m_currentLoaded.js[i]);
				console.log(m_currentLoaded.js[i].src + " unloaded.");
			}
			m_currentLoaded.js = [];
			for (var i = 0; i < m_currentLoaded.pages.length; i++) {

				var pageId = m_currentLoaded.pages[i];
				var idx = pageId.lastIndexOf(".");
				var pkg = et.provide(pageId.slice(0, idx));
				pkg[pageId.slice(idx + 1)] = undefined;
				delete m_currentLoaded.pageId;
				console.log(pageId + " unloaded.");
			}
			m_currentLoaded.pages = [];

		}

		et.debug = et.DefaultPageParams[C.DEBUG] = et.getDebug();
		var tmp = p_pageDef.name.split(".").join("/") + (et.debug ? ".js" : ".js");
		console.log("loading merged page: " + tmp);
		
		if (p_pageDef.requires) {
			et.loadScript(p_pageDef.requires, (function(pp_pageDef, pp_handler, pp_controller) {
				return function() {
					var presenter = et.provide(pp_pageDef.name);
					presenter._viewPath = pp_pageDef.path;
					if(!presenter.loadRegions){
						console.error(pp_pageDef);
					}
					presenter.loadRegions(pp_pageDef.regions, pp_handler, pp_controller, pp_pageDef, true);
				};
			})(p_pageDef, p_handler, p_controller));
		} else {
			var presenter = et.provide(p_pageDef.name);
			presenter._viewPath = p_pageDef.path;
			if(!presenter.loadRegions){
				console.error(p_pageDef);
			}
			presenter.loadRegions(p_pageDef.regions, p_handler, p_controller, p_pageDef, true);
		}
	};

})();
